import { default as bankjOqt3ivAq9Meta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/bank.vue?macro=true";
import { default as bonuscSF6SRv0VuMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/bonus.vue?macro=true";
import { default as bankZXrF6rWD1aMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/deposit/bank.vue?macro=true";
import { default as cardGAZgXaEea2Meta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/deposit/card.vue?macro=true";
import { default as codepayYWQSKwFo4oMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/deposit/codepay.vue?macro=true";
import { default as codepay2jedI5SvVy5Meta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/deposit/codepay2.vue?macro=true";
import { default as crypto3QX07QOajWMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/deposit/crypto.vue?macro=true";
import { default as ewalletSWwVBnf1yWMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/deposit/ewallet.vue?macro=true";
import { default as bet9JVTlqmHfHMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/history/bet.vue?macro=true";
import { default as transactionhSxE2hyXKjMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/history/transaction.vue?macro=true";
import { default as historyhHlDLwm2kyMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/history.vue?macro=true";
import { default as profile8MkqQpkgdGMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/profile.vue?macro=true";
import { default as trading_45p2p8y1qzGxnIaMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/trading-p2p.vue?macro=true";
import { default as bankx9mIzIG0P6Meta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/withdraw/bank.vue?macro=true";
import { default as cardk5IPoOKgFpMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/withdraw/card.vue?macro=true";
import { default as cryptoxSMuE4DsXrMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/withdraw/crypto.vue?macro=true";
import { default as account6zxHO8jzItMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account.vue?macro=true";
import { default as base_45buttonxT32YEIFiwMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-button.vue?macro=true";
import { default as base_45count_45downRGMwoDsXYrMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-count-down.vue?macro=true";
import { default as base_45dropdown_45radio3VzhgAEP2bMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-dropdown-radio.vue?macro=true";
import { default as base_45imgEe3wGSFutNMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-img.vue?macro=true";
import { default as base_45inputqhV1UTcRdoMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-input.vue?macro=true";
import { default as base_45loadingSB6CZVw4aLMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-loading.vue?macro=true";
import { default as base_45modal0j9L3hhJhhMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-modal.vue?macro=true";
import { default as base_45money_45input2pwq8bkmDwMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-money-input.vue?macro=true";
import { default as base_45paginationuN5atGAT72Meta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-pagination.vue?macro=true";
import { default as base_45select_45bankEGbpoj5PDXMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-select-bank.vue?macro=true";
import { default as game_45itemDI5lL2wlmBMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/game-item.vue?macro=true";
import { default as index6fAoCIndrcMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/index.vue?macro=true";
import { default as _91type_93OYSWOGSrAeMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/game/[type].vue?macro=true";
import { default as gameGrFiktetQ0Meta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/game.vue?macro=true";
import { default as _91alias_93YIdq9z50LoMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/gioi-thieu/[alias].vue?macro=true";
import { default as gioi_45thieuzk4q6gwqXYMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/gioi-thieu.vue?macro=true";
import { default as _91alias_93yzqKi3WfK1Meta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/huong-dan/[alias].vue?macro=true";
import { default as huong_45dandWL4Ljti1cMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/huong-dan.vue?macro=true";
import { default as index7jlLulT0wXMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/index.vue?macro=true";
import { default as _91id_93tvHAa4ViNUMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/khuyen-mai/[id].vue?macro=true";
import { default as index3h2gVqnURAMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/khuyen-mai/index.vue?macro=true";
import { default as _91id_93xJbYaGoE6YMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/khuyen-mai/the-loai/[id].vue?macro=true";
import { default as vipH5XxWg8vCNMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/khuyen-mai/vip.vue?macro=true";
import { default as khuyen_45mairYk6kwuwGuMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/khuyen-mai.vue?macro=true";
import { default as _91type_93WGqnSrV9xfMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/livecasino/[type].vue?macro=true";
import { default as indexdyR5CvSi3BMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/livecasino/index.vue?macro=true";
import { default as livecasinoofDo9705peMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/livecasino.vue?macro=true";
import { default as reset_45passwordkIV2m6OAsdMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/reset-password.vue?macro=true";
import { default as _91id_93k5rkvzbEfPMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/the-thao/[id].vue?macro=true";
import { default as index2glEFJTKGrMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/the-thao/index.vue?macro=true";
import { default as index8tIZRXSCcAMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/the-thao/lich-thi-dau/chi-tiet/index.vue?macro=true";
import { default as index4lzd6nwyZgMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/the-thao/lich-thi-dau/index.vue?macro=true";
import { default as _91alias_93xF5cqSyDtgMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/tin-tuc/[alias].vue?macro=true";
import { default as index1mTDyawtDyMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/tin-tuc/index.vue?macro=true";
import { default as _91Slug_93YzStU6BZa5Meta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/tin-tuc/the-loai/[Slug].vue?macro=true";
import { default as tin_45tuclNBgvvGqPvMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/tin-tuc.vue?macro=true";
import { default as verify_45mailbBRB1x1QChMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/verify-mail.vue?macro=true";
export default [
  {
    name: account6zxHO8jzItMeta?.name ?? "account",
    path: account6zxHO8jzItMeta?.path ?? "/account",
    children: [
  {
    name: bankjOqt3ivAq9Meta?.name ?? "account-bank",
    path: bankjOqt3ivAq9Meta?.path ?? "bank",
    meta: bankjOqt3ivAq9Meta || {},
    alias: bankjOqt3ivAq9Meta?.alias || [],
    redirect: bankjOqt3ivAq9Meta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/bank.vue").then(m => m.default || m)
  },
  {
    name: bonuscSF6SRv0VuMeta?.name ?? "account-bonus",
    path: bonuscSF6SRv0VuMeta?.path ?? "bonus",
    meta: bonuscSF6SRv0VuMeta || {},
    alias: bonuscSF6SRv0VuMeta?.alias || [],
    redirect: bonuscSF6SRv0VuMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/bonus.vue").then(m => m.default || m)
  },
  {
    name: bankZXrF6rWD1aMeta?.name ?? "account-deposit-bank",
    path: bankZXrF6rWD1aMeta?.path ?? "deposit/bank",
    meta: bankZXrF6rWD1aMeta || {},
    alias: bankZXrF6rWD1aMeta?.alias || [],
    redirect: bankZXrF6rWD1aMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/deposit/bank.vue").then(m => m.default || m)
  },
  {
    name: cardGAZgXaEea2Meta?.name ?? "account-deposit-card",
    path: cardGAZgXaEea2Meta?.path ?? "deposit/card",
    meta: cardGAZgXaEea2Meta || {},
    alias: cardGAZgXaEea2Meta?.alias || [],
    redirect: cardGAZgXaEea2Meta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/deposit/card.vue").then(m => m.default || m)
  },
  {
    name: codepayYWQSKwFo4oMeta?.name ?? "account-deposit-codepay",
    path: codepayYWQSKwFo4oMeta?.path ?? "deposit/codepay",
    meta: codepayYWQSKwFo4oMeta || {},
    alias: codepayYWQSKwFo4oMeta?.alias || [],
    redirect: codepayYWQSKwFo4oMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/deposit/codepay.vue").then(m => m.default || m)
  },
  {
    name: codepay2jedI5SvVy5Meta?.name ?? "account-deposit-codepay2",
    path: codepay2jedI5SvVy5Meta?.path ?? "deposit/codepay2",
    meta: codepay2jedI5SvVy5Meta || {},
    alias: codepay2jedI5SvVy5Meta?.alias || [],
    redirect: codepay2jedI5SvVy5Meta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/deposit/codepay2.vue").then(m => m.default || m)
  },
  {
    name: crypto3QX07QOajWMeta?.name ?? "account-deposit-crypto",
    path: crypto3QX07QOajWMeta?.path ?? "deposit/crypto",
    meta: crypto3QX07QOajWMeta || {},
    alias: crypto3QX07QOajWMeta?.alias || [],
    redirect: crypto3QX07QOajWMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/deposit/crypto.vue").then(m => m.default || m)
  },
  {
    name: ewalletSWwVBnf1yWMeta?.name ?? "account-deposit-ewallet",
    path: ewalletSWwVBnf1yWMeta?.path ?? "deposit/ewallet",
    meta: ewalletSWwVBnf1yWMeta || {},
    alias: ewalletSWwVBnf1yWMeta?.alias || [],
    redirect: ewalletSWwVBnf1yWMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/deposit/ewallet.vue").then(m => m.default || m)
  },
  {
    name: historyhHlDLwm2kyMeta?.name ?? "account-history",
    path: historyhHlDLwm2kyMeta?.path ?? "history",
    children: [
  {
    name: bet9JVTlqmHfHMeta?.name ?? "account-history-bet",
    path: bet9JVTlqmHfHMeta?.path ?? "bet",
    meta: bet9JVTlqmHfHMeta || {},
    alias: bet9JVTlqmHfHMeta?.alias || [],
    redirect: bet9JVTlqmHfHMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/history/bet.vue").then(m => m.default || m)
  },
  {
    name: transactionhSxE2hyXKjMeta?.name ?? "account-history-transaction",
    path: transactionhSxE2hyXKjMeta?.path ?? "transaction",
    meta: transactionhSxE2hyXKjMeta || {},
    alias: transactionhSxE2hyXKjMeta?.alias || [],
    redirect: transactionhSxE2hyXKjMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/history/transaction.vue").then(m => m.default || m)
  }
],
    meta: historyhHlDLwm2kyMeta || {},
    alias: historyhHlDLwm2kyMeta?.alias || [],
    redirect: historyhHlDLwm2kyMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: profile8MkqQpkgdGMeta?.name ?? "account-profile",
    path: profile8MkqQpkgdGMeta?.path ?? "profile",
    meta: profile8MkqQpkgdGMeta || {},
    alias: profile8MkqQpkgdGMeta?.alias || [],
    redirect: profile8MkqQpkgdGMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: trading_45p2p8y1qzGxnIaMeta?.name ?? "account-trading-p2p",
    path: trading_45p2p8y1qzGxnIaMeta?.path ?? "trading-p2p",
    meta: trading_45p2p8y1qzGxnIaMeta || {},
    alias: trading_45p2p8y1qzGxnIaMeta?.alias || [],
    redirect: trading_45p2p8y1qzGxnIaMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/trading-p2p.vue").then(m => m.default || m)
  },
  {
    name: bankx9mIzIG0P6Meta?.name ?? "account-withdraw-bank",
    path: bankx9mIzIG0P6Meta?.path ?? "withdraw/bank",
    meta: bankx9mIzIG0P6Meta || {},
    alias: bankx9mIzIG0P6Meta?.alias || [],
    redirect: bankx9mIzIG0P6Meta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/withdraw/bank.vue").then(m => m.default || m)
  },
  {
    name: cardk5IPoOKgFpMeta?.name ?? "account-withdraw-card",
    path: cardk5IPoOKgFpMeta?.path ?? "withdraw/card",
    meta: cardk5IPoOKgFpMeta || {},
    alias: cardk5IPoOKgFpMeta?.alias || [],
    redirect: cardk5IPoOKgFpMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/withdraw/card.vue").then(m => m.default || m)
  },
  {
    name: cryptoxSMuE4DsXrMeta?.name ?? "account-withdraw-crypto",
    path: cryptoxSMuE4DsXrMeta?.path ?? "withdraw/crypto",
    meta: cryptoxSMuE4DsXrMeta || {},
    alias: cryptoxSMuE4DsXrMeta?.alias || [],
    redirect: cryptoxSMuE4DsXrMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account/withdraw/crypto.vue").then(m => m.default || m)
  }
],
    meta: account6zxHO8jzItMeta || {},
    alias: account6zxHO8jzItMeta?.alias || [],
    redirect: account6zxHO8jzItMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: base_45buttonxT32YEIFiwMeta?.name ?? "dev-base-button",
    path: base_45buttonxT32YEIFiwMeta?.path ?? "/dev/base-button",
    meta: base_45buttonxT32YEIFiwMeta || {},
    alias: base_45buttonxT32YEIFiwMeta?.alias || [],
    redirect: base_45buttonxT32YEIFiwMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-button.vue").then(m => m.default || m)
  },
  {
    name: base_45count_45downRGMwoDsXYrMeta?.name ?? "dev-base-count-down",
    path: base_45count_45downRGMwoDsXYrMeta?.path ?? "/dev/base-count-down",
    meta: base_45count_45downRGMwoDsXYrMeta || {},
    alias: base_45count_45downRGMwoDsXYrMeta?.alias || [],
    redirect: base_45count_45downRGMwoDsXYrMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-count-down.vue").then(m => m.default || m)
  },
  {
    name: base_45dropdown_45radio3VzhgAEP2bMeta?.name ?? "dev-base-dropdown-radio",
    path: base_45dropdown_45radio3VzhgAEP2bMeta?.path ?? "/dev/base-dropdown-radio",
    meta: base_45dropdown_45radio3VzhgAEP2bMeta || {},
    alias: base_45dropdown_45radio3VzhgAEP2bMeta?.alias || [],
    redirect: base_45dropdown_45radio3VzhgAEP2bMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-dropdown-radio.vue").then(m => m.default || m)
  },
  {
    name: base_45imgEe3wGSFutNMeta?.name ?? "dev-base-img",
    path: base_45imgEe3wGSFutNMeta?.path ?? "/dev/base-img",
    meta: base_45imgEe3wGSFutNMeta || {},
    alias: base_45imgEe3wGSFutNMeta?.alias || [],
    redirect: base_45imgEe3wGSFutNMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-img.vue").then(m => m.default || m)
  },
  {
    name: base_45inputqhV1UTcRdoMeta?.name ?? "dev-base-input",
    path: base_45inputqhV1UTcRdoMeta?.path ?? "/dev/base-input",
    meta: base_45inputqhV1UTcRdoMeta || {},
    alias: base_45inputqhV1UTcRdoMeta?.alias || [],
    redirect: base_45inputqhV1UTcRdoMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-input.vue").then(m => m.default || m)
  },
  {
    name: base_45loadingSB6CZVw4aLMeta?.name ?? "dev-base-loading",
    path: base_45loadingSB6CZVw4aLMeta?.path ?? "/dev/base-loading",
    meta: base_45loadingSB6CZVw4aLMeta || {},
    alias: base_45loadingSB6CZVw4aLMeta?.alias || [],
    redirect: base_45loadingSB6CZVw4aLMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-loading.vue").then(m => m.default || m)
  },
  {
    name: base_45modal0j9L3hhJhhMeta?.name ?? "dev-base-modal",
    path: base_45modal0j9L3hhJhhMeta?.path ?? "/dev/base-modal",
    meta: base_45modal0j9L3hhJhhMeta || {},
    alias: base_45modal0j9L3hhJhhMeta?.alias || [],
    redirect: base_45modal0j9L3hhJhhMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45money_45input2pwq8bkmDwMeta?.name ?? "dev-base-money-input",
    path: base_45money_45input2pwq8bkmDwMeta?.path ?? "/dev/base-money-input",
    meta: base_45money_45input2pwq8bkmDwMeta || {},
    alias: base_45money_45input2pwq8bkmDwMeta?.alias || [],
    redirect: base_45money_45input2pwq8bkmDwMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-money-input.vue").then(m => m.default || m)
  },
  {
    name: base_45paginationuN5atGAT72Meta?.name ?? "dev-base-pagination",
    path: base_45paginationuN5atGAT72Meta?.path ?? "/dev/base-pagination",
    meta: base_45paginationuN5atGAT72Meta || {},
    alias: base_45paginationuN5atGAT72Meta?.alias || [],
    redirect: base_45paginationuN5atGAT72Meta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-pagination.vue").then(m => m.default || m)
  },
  {
    name: base_45select_45bankEGbpoj5PDXMeta?.name ?? "dev-base-select-bank",
    path: base_45select_45bankEGbpoj5PDXMeta?.path ?? "/dev/base-select-bank",
    meta: base_45select_45bankEGbpoj5PDXMeta || {},
    alias: base_45select_45bankEGbpoj5PDXMeta?.alias || [],
    redirect: base_45select_45bankEGbpoj5PDXMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/base-select-bank.vue").then(m => m.default || m)
  },
  {
    name: game_45itemDI5lL2wlmBMeta?.name ?? "dev-game-item",
    path: game_45itemDI5lL2wlmBMeta?.path ?? "/dev/game-item",
    meta: game_45itemDI5lL2wlmBMeta || {},
    alias: game_45itemDI5lL2wlmBMeta?.alias || [],
    redirect: game_45itemDI5lL2wlmBMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/game-item.vue").then(m => m.default || m)
  },
  {
    name: index6fAoCIndrcMeta?.name ?? "dev",
    path: index6fAoCIndrcMeta?.path ?? "/dev",
    meta: index6fAoCIndrcMeta || {},
    alias: index6fAoCIndrcMeta?.alias || [],
    redirect: index6fAoCIndrcMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: gameGrFiktetQ0Meta?.name ?? "game",
    path: gameGrFiktetQ0Meta?.path ?? "/game",
    children: [
  {
    name: _91type_93OYSWOGSrAeMeta?.name ?? "game-type",
    path: _91type_93OYSWOGSrAeMeta?.path ?? ":type",
    meta: _91type_93OYSWOGSrAeMeta || {},
    alias: _91type_93OYSWOGSrAeMeta?.alias || [],
    redirect: _91type_93OYSWOGSrAeMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/game/[type].vue").then(m => m.default || m)
  }
],
    meta: gameGrFiktetQ0Meta || {},
    alias: gameGrFiktetQ0Meta?.alias || [],
    redirect: gameGrFiktetQ0Meta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/game.vue").then(m => m.default || m)
  },
  {
    name: gioi_45thieuzk4q6gwqXYMeta?.name ?? "gioi-thieu",
    path: gioi_45thieuzk4q6gwqXYMeta?.path ?? "/gioi-thieu",
    children: [
  {
    name: _91alias_93YIdq9z50LoMeta?.name ?? "gioi-thieu-alias",
    path: _91alias_93YIdq9z50LoMeta?.path ?? ":alias",
    meta: _91alias_93YIdq9z50LoMeta || {},
    alias: _91alias_93YIdq9z50LoMeta?.alias || [],
    redirect: _91alias_93YIdq9z50LoMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/gioi-thieu/[alias].vue").then(m => m.default || m)
  }
],
    meta: gioi_45thieuzk4q6gwqXYMeta || {},
    alias: gioi_45thieuzk4q6gwqXYMeta?.alias || [],
    redirect: gioi_45thieuzk4q6gwqXYMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: huong_45dandWL4Ljti1cMeta?.name ?? "huong-dan",
    path: huong_45dandWL4Ljti1cMeta?.path ?? "/huong-dan",
    children: [
  {
    name: _91alias_93yzqKi3WfK1Meta?.name ?? "huong-dan-alias",
    path: _91alias_93yzqKi3WfK1Meta?.path ?? ":alias",
    meta: _91alias_93yzqKi3WfK1Meta || {},
    alias: _91alias_93yzqKi3WfK1Meta?.alias || [],
    redirect: _91alias_93yzqKi3WfK1Meta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/huong-dan/[alias].vue").then(m => m.default || m)
  }
],
    meta: huong_45dandWL4Ljti1cMeta || {},
    alias: huong_45dandWL4Ljti1cMeta?.alias || [],
    redirect: huong_45dandWL4Ljti1cMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/huong-dan.vue").then(m => m.default || m)
  },
  {
    name: index7jlLulT0wXMeta?.name ?? "index",
    path: index7jlLulT0wXMeta?.path ?? "/",
    meta: index7jlLulT0wXMeta || {},
    alias: index7jlLulT0wXMeta?.alias || [],
    redirect: index7jlLulT0wXMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/index.vue").then(m => m.default || m)
  },
  {
    path: khuyen_45mairYk6kwuwGuMeta?.path ?? "/khuyen-mai",
    children: [
  {
    name: _91id_93tvHAa4ViNUMeta?.name ?? "khuyen-mai-id",
    path: _91id_93tvHAa4ViNUMeta?.path ?? ":id",
    meta: _91id_93tvHAa4ViNUMeta || {},
    alias: _91id_93tvHAa4ViNUMeta?.alias || [],
    redirect: _91id_93tvHAa4ViNUMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/khuyen-mai/[id].vue").then(m => m.default || m)
  },
  {
    name: index3h2gVqnURAMeta?.name ?? "khuyen-mai",
    path: index3h2gVqnURAMeta?.path ?? "",
    meta: index3h2gVqnURAMeta || {},
    alias: index3h2gVqnURAMeta?.alias || [],
    redirect: index3h2gVqnURAMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/khuyen-mai/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xJbYaGoE6YMeta?.name ?? "khuyen-mai-the-loai-id",
    path: _91id_93xJbYaGoE6YMeta?.path ?? "the-loai/:id",
    meta: _91id_93xJbYaGoE6YMeta || {},
    alias: _91id_93xJbYaGoE6YMeta?.alias || [],
    redirect: _91id_93xJbYaGoE6YMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/khuyen-mai/the-loai/[id].vue").then(m => m.default || m)
  },
  {
    name: vipH5XxWg8vCNMeta?.name ?? "khuyen-mai-vip",
    path: vipH5XxWg8vCNMeta?.path ?? "vip",
    meta: vipH5XxWg8vCNMeta || {},
    alias: vipH5XxWg8vCNMeta?.alias || [],
    redirect: vipH5XxWg8vCNMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/khuyen-mai/vip.vue").then(m => m.default || m)
  }
],
    name: khuyen_45mairYk6kwuwGuMeta?.name ?? undefined,
    meta: khuyen_45mairYk6kwuwGuMeta || {},
    alias: khuyen_45mairYk6kwuwGuMeta?.alias || [],
    redirect: khuyen_45mairYk6kwuwGuMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/khuyen-mai.vue").then(m => m.default || m)
  },
  {
    path: livecasinoofDo9705peMeta?.path ?? "/livecasino",
    children: [
  {
    name: _91type_93WGqnSrV9xfMeta?.name ?? "livecasino-type",
    path: _91type_93WGqnSrV9xfMeta?.path ?? ":type",
    meta: _91type_93WGqnSrV9xfMeta || {},
    alias: _91type_93WGqnSrV9xfMeta?.alias || [],
    redirect: _91type_93WGqnSrV9xfMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/livecasino/[type].vue").then(m => m.default || m)
  },
  {
    name: indexdyR5CvSi3BMeta?.name ?? "livecasino",
    path: indexdyR5CvSi3BMeta?.path ?? "",
    meta: indexdyR5CvSi3BMeta || {},
    alias: indexdyR5CvSi3BMeta?.alias || [],
    redirect: indexdyR5CvSi3BMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/livecasino/index.vue").then(m => m.default || m)
  }
],
    name: livecasinoofDo9705peMeta?.name ?? undefined,
    meta: livecasinoofDo9705peMeta || {},
    alias: livecasinoofDo9705peMeta?.alias || [],
    redirect: livecasinoofDo9705peMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/livecasino.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordkIV2m6OAsdMeta?.name ?? "reset-password",
    path: reset_45passwordkIV2m6OAsdMeta?.path ?? "/reset-password",
    meta: reset_45passwordkIV2m6OAsdMeta || {},
    alias: reset_45passwordkIV2m6OAsdMeta?.alias || [],
    redirect: reset_45passwordkIV2m6OAsdMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_93k5rkvzbEfPMeta?.name ?? "the-thao-id",
    path: _91id_93k5rkvzbEfPMeta?.path ?? "/the-thao/:id",
    meta: _91id_93k5rkvzbEfPMeta || {},
    alias: _91id_93k5rkvzbEfPMeta?.alias || [],
    redirect: _91id_93k5rkvzbEfPMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/the-thao/[id].vue").then(m => m.default || m)
  },
  {
    name: index2glEFJTKGrMeta?.name ?? "the-thao",
    path: index2glEFJTKGrMeta?.path ?? "/the-thao",
    meta: index2glEFJTKGrMeta || {},
    alias: index2glEFJTKGrMeta?.alias || [],
    redirect: index2glEFJTKGrMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/the-thao/index.vue").then(m => m.default || m)
  },
  {
    name: index8tIZRXSCcAMeta?.name ?? "the-thao-lich-thi-dau-chi-tiet",
    path: index8tIZRXSCcAMeta?.path ?? "/the-thao/lich-thi-dau/chi-tiet",
    meta: index8tIZRXSCcAMeta || {},
    alias: index8tIZRXSCcAMeta?.alias || [],
    redirect: index8tIZRXSCcAMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/the-thao/lich-thi-dau/chi-tiet/index.vue").then(m => m.default || m)
  },
  {
    name: index4lzd6nwyZgMeta?.name ?? "the-thao-lich-thi-dau",
    path: index4lzd6nwyZgMeta?.path ?? "/the-thao/lich-thi-dau",
    meta: index4lzd6nwyZgMeta || {},
    alias: index4lzd6nwyZgMeta?.alias || [],
    redirect: index4lzd6nwyZgMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/the-thao/lich-thi-dau/index.vue").then(m => m.default || m)
  },
  {
    path: tin_45tuclNBgvvGqPvMeta?.path ?? "/tin-tuc",
    children: [
  {
    name: _91alias_93xF5cqSyDtgMeta?.name ?? "tin-tuc-alias",
    path: _91alias_93xF5cqSyDtgMeta?.path ?? ":alias",
    meta: _91alias_93xF5cqSyDtgMeta || {},
    alias: _91alias_93xF5cqSyDtgMeta?.alias || [],
    redirect: _91alias_93xF5cqSyDtgMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/tin-tuc/[alias].vue").then(m => m.default || m)
  },
  {
    name: index1mTDyawtDyMeta?.name ?? "tin-tuc",
    path: index1mTDyawtDyMeta?.path ?? "",
    meta: index1mTDyawtDyMeta || {},
    alias: index1mTDyawtDyMeta?.alias || [],
    redirect: index1mTDyawtDyMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91Slug_93YzStU6BZa5Meta?.name ?? "tin-tuc-the-loai-Slug",
    path: _91Slug_93YzStU6BZa5Meta?.path ?? "the-loai/:Slug",
    meta: _91Slug_93YzStU6BZa5Meta || {},
    alias: _91Slug_93YzStU6BZa5Meta?.alias || [],
    redirect: _91Slug_93YzStU6BZa5Meta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/tin-tuc/the-loai/[Slug].vue").then(m => m.default || m)
  }
],
    name: tin_45tuclNBgvvGqPvMeta?.name ?? undefined,
    meta: tin_45tuclNBgvvGqPvMeta || {},
    alias: tin_45tuclNBgvvGqPvMeta?.alias || [],
    redirect: tin_45tuclNBgvvGqPvMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/tin-tuc.vue").then(m => m.default || m)
  },
  {
    name: verify_45mailbBRB1x1QChMeta?.name ?? "verify-mail",
    path: verify_45mailbBRB1x1QChMeta?.path ?? "/verify-mail",
    meta: verify_45mailbBRB1x1QChMeta || {},
    alias: verify_45mailbBRB1x1QChMeta?.alias || [],
    redirect: verify_45mailbBRB1x1QChMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps27-web/pages/verify-mail.vue").then(m => m.default || m)
  }
]