import { NCC_TYPES } from '~/constants/common'

// Base URLs
const BASE = {
  CASINO: '/livecasino',
  ACCOUNT: '/account',
  GUIDE: '/huong-dan',
  PROMOTION: '/khuyen-mai',
  SPORT: '/the-thao',
  NEWS: '/tin-tuc',
  GAME: '/game',
  INTRODUCTION: '/gioi-thieu',
  MAINTAIN: '/maintain'
}

// Page URLs
export const PAGE_URLS = {
  HOME: '/',
  INTRODUCTION: BASE.INTRODUCTION,
  POLICY: `${BASE.INTRODUCTION}/dieu-khoan`,
  SECURITY: `${BASE.INTRODUCTION}/chinh-sach-bao-mat`,
  RESPONSIBILITY: `${BASE.INTRODUCTION}/trach-nhiem`,
  RULE: `${BASE.INTRODUCTION}/quy-dinh-chung`,
  NEWS: BASE.NEWS,
  NEWS_CATEGORY: `${BASE.NEWS}/the-loai`,
  GUIDE: BASE.GUIDE,
  GUIDE_WITHDRAW: `${BASE.GUIDE}/rut-tien`,
  PROMOTION: `${BASE.PROMOTION}`,
  PROMOTION_VIP: `${BASE.PROMOTION}/vip`,
  PROMOTION_CATEGORY: `${BASE.PROMOTION}/the-loai`,
  PROMOTION_DETAIL: `${BASE.PROMOTION}/detail`,
  GAME: BASE.GAME,
  CASINO: BASE.CASINO,
  SPORT: BASE.SPORT,
  ACCOUNT: BASE.ACCOUNT,
  MAINTAIN: '/maintenence',
  SCHEDULES: `${BASE.SPORT}/lich-thi-dau`,
  SCHEDULE_MATCH: `${BASE.SPORT}/lich-thi-dau/chi-tiet`,
  PROFIT_TODAY: `${BASE.ACCOUNT}/bonus`
}

export const ACCOUNT_URLS = {
  INDEX: BASE.ACCOUNT,
  PROFILE: `${BASE.ACCOUNT}/profile`,
  BANK: `${BASE.ACCOUNT}/bank`,
  INFO_USER: `${BASE.ACCOUNT}/information/user`,
  ADD_BANK: `${BASE.ACCOUNT}/information/bank?currentTab=ADD_BANK`,
  DEPOSIT: `${BASE.ACCOUNT}/deposit`,
  DEPOSIT_TA_PAY: `${BASE.ACCOUNT}/deposit/ta-pay`,
  DEPOSIT_DA: `${BASE.ACCOUNT}/deposit/bank?type=codepay`,
  DEPOSIT_BANK: `${BASE.ACCOUNT}/deposit/bank`,
  DEPOSIT_CODEPAY2: `${BASE.ACCOUNT}/deposit/bank?type=codepay2`,
  DEPOSIT_MOMO: `${BASE.ACCOUNT}/deposit/ewallet?type=momo`,
  DEPOSIT_CARD: `${BASE.ACCOUNT}/deposit/card`,
  DEPOSIT_CRYPTO: `${BASE.ACCOUNT}/deposit/crypto`,
  DEPOSIT_VIETTEL_PAY: `${BASE.ACCOUNT}/deposit/ewallet?type=viettelpay`,
  DEPOSIT_ZALO_PAY: `${BASE.ACCOUNT}/deposit/ewallet?type=zalopay`,
  WITHDRAW: `${BASE.ACCOUNT}/withdraw`,
  WITHDRAW_BANK: `${BASE.ACCOUNT}/withdraw/bank`,
  WITHDRAW_CRYPTO: `${BASE.ACCOUNT}/withdraw/crypto`,
  WITHDRAW_CARD: `${BASE.ACCOUNT}/withdraw/card`,
  TRANSACTION_HISTORY: `${BASE.ACCOUNT}/history/transaction`,
  BET_HISTORY: `${BASE.ACCOUNT}/history/bet`,
  PROFIT_TODAY: `${BASE.ACCOUNT}/bonus`,
  P2P: `${BASE.ACCOUNT}/trading-p2p`,
  TRANSACTION_P2P: `${BASE.ACCOUNT}/trading-p2p`
}

// Lobby Game URLs
export const LOBBY_GAME_URLS = {
  ALL: BASE.GAME,
  LO_DE: `${BASE.GAME}/lo-de`,
  BAN_CA: `${BASE.GAME}/ban-ca`,
  QUAY_SO: `${BASE.GAME}/quay-so`,
  TABLE_GAME: `${BASE.GAME}/table-game`,
  GAME_BAI: `${BASE.GAME}/game-bai`,
  SLOTS_GAME: `${BASE.GAME}/slots`,
  NO_HU: `${BASE.GAME}/no-hu`,
  INGAME: `${BASE.GAME}/ingame`,
  QUICK_GAMES: `${BASE.GAME}/game-nhanh`,
  V_GAMING: `${BASE.GAME}/v-gaming`,
  LIVE_STREAM: `${BASE.GAME}/livestream`,
  GAME: `${BASE.GAME}`
}

// Lobby Casino URLs
export const LOBBY_CASINO_URLS = {
  ALL: BASE.CASINO,
  ALL_CASINO: `${BASE.CASINO}/all`,
  TAIXIU: `${BASE.CASINO}/taixiu`,
  XOCDIA: `${BASE.CASINO}/xocdia`,
  BAUCUA: `${BASE.CASINO}/baucua`,
  ROULETTE: `${BASE.CASINO}/roulette`,
  BACCARAT: `${BASE.CASINO}/baccarat`,
  POKER: `${BASE.CASINO}/poker`,
  SICBO: `${BASE.CASINO}/sicbo`,
  BLACKJACK: `${BASE.CASINO}/blackjack`,
  DRAGONTIGER: `${BASE.CASINO}/dragontiger`,
  OTHERS: `${BASE.CASINO}/others`,
  LIVESTREAM: `${BASE.CASINO}/livestream`,
  CASINO_RIK: `${BASE.CASINO}/all?partner=rik`,
  CASINO_EVO: `${BASE.CASINO}/all?partner=evo`,
  CASINO_GO88: `${BASE.CASINO}/all?partner=go`,
  CASINO_PRAMATIC: `${BASE.CASINO}/all?partner=pragmatic`,
  CASINO_VIVO: `${BASE.CASINO}/all?partner=vivo`
}

// Iframe Game URLs
export const SPORTS_URLS = {
  INDEX: BASE.SPORT,
  SCHEDULES: `${BASE.SPORT}/lich-thi-dau`,
  K_SPORT: `${BASE.SPORT}/${NCC_TYPES.KSPORTS}`,
  C_SPORT: `${BASE.SPORT}/${NCC_TYPES.CSPORTS}`,
  A_SPORT: `${BASE.SPORT}/${NCC_TYPES.ASPORTS}`,
  S_SPORT: `${BASE.SPORT}/${NCC_TYPES.SSPORTS}`,
  P_SPORT: `${BASE.SPORT}/${NCC_TYPES.PSPORTS}`,
  M_SPORT: `${BASE.SPORT}/${NCC_TYPES.MSPORTS}`,
  E_SPORT: `${BASE.SPORT}/${NCC_TYPES.ESPORTS}`,
  T_SPORT: `${BASE.SPORT}/${NCC_TYPES.TSPORTS}`,
  I_SPORT: `${BASE.SPORT}/${NCC_TYPES.ISPORTS}`,
  VIRTUAL_SPORTS: `${BASE.SPORT}/${NCC_TYPES.VIRTUAL_SPORTS}`,
  SCHEDULES_DETAILS: `${BASE.SPORT}/lich-thi-dau/chi-tiet`
}

export const PROMOTION_URLS = {
  promotion_300: 'thuong-nap-lan-nap-dau-300percent',
  promotion_150: 'thuong-chao-mung-150percent',
  promotion_100: 'thuong-chao-mung-100percent',
  return_1: 'hoan-tra-the-thao-158-moi-ngay-tai-da88'
}
